import cn from 'classnames';

import { AccountRoutes } from '@webapp/account/src/resources/routes';

import { Button } from 'components/ui/button';
import { Sizer } from 'components/ui/sizer';

// eslint-disable-next-line import/no-unresolved
// eslint-disable-next-line import/no-unresolved
import Bg from './img/bg.svg?noSvgo';
import Girl from './img/girl.svg';
import Line from './img/line-wide-desktop.svg';
import Points from './img/points.svg';
import Tablet from './img/tablet-smil.svg?noSvgo';

import css from './connect.css';

/*
 * https://disk.yandex.ru/d/-LJSBC4RiXev8w
 * https://svgsprite.com/tools/lottie-player/?render=canvas
 * https://lottiefiles.com/svg-to-lottie/convert
 * */

export const Connect: FC = () => (
    <section className={css.connect}>
        <svg className={css.clip}>
            <clipPath clipPathUnits='objectBoundingBox' id='bgClip'>
                {/* https://yoksel.github.io/relative-clip-path/ */}
                <path d='M0.038,0.736 C0.006,0.74,-0.028,0.695,-0.036,0.678 l-0.029,-0.74,0.856,0.032 c0.004,-0.007,0.147,-0.03,0.144,0.029 c-0.021,0.298,-0.447,0.086,-0.395,0.418 c0.052,0.332,-0.031,0.461,-0.076,0.494 c-0.045,0.033,-0.089,0.06,-0.196,-0.077 C0.149,0.683,0.078,0.731,0.038,0.736' />
            </clipPath>
            <clipPath clipPathUnits='objectBoundingBox' id='bgClipMobile'>
                {/*<path d='M102.536 134.946c-73.531 17.066-124.09-29.052-141.347-58.454l-15.34-159.15 416.732-40.907 33.237 217.954C377.921 57.317 329.089-15.035 276.934-7.877c-65.194 8.948-76.168 120.024-174.398 142.823Z' />*/}
                {/*<path d='M102.536 134.946C29.0046 152.012-21.5532 105.894-38.8106 76.4922V-84.108H362.581L295.568-17.703 276.934-7.8768C211.74 1.0714 200.766 112.147 102.536 134.946Z' />*/}
                <path d='M0.183,0.601 C0,0.678,-0.126,0.471,-0.169,0.339 V-0.382 H0.831 L0.664,-0.084,0.618,-0.04 C0.455,0,0.428,0.499,0.183,0.601' />
            </clipPath>
        </svg>
        <Sizer height={878} width={1558} />
        <div className={css.bg2}>
            <Sizer height={878} width={1558} />
            <Bg className={css.background} />
            <div className={cn(css.zigzag, css.zigzag1)}>
                <svg viewBox='0 0 3 2' />
                <div />
            </div>
            <div className={cn(css.zigzag, css.zigzag2)}>
                <svg viewBox='0 0 3 2' />
                <div />
            </div>
            <div className={cn(css.zigzag, css.zigzag3)}>
                <svg viewBox='0 0 3 2' />
                <div />
            </div>
            <Points className={css.points} />
        </div>
        <Girl className={css.girl} />
        <Tablet className={css.tablet} style={{ opacity: 0 }} />
        <div className={css.content}>
            <h1 className={css.title}>Профессиональный сервис опросов</h1>
            <div className={css.infographics}>
                <div className={css.line}>
                    <Line />
                    <h4 className={cn(css.point, css.line1, css.tests)}>тестов</h4>
                    <h4 className={cn(css.point, css.line1, css.analytics)}>аналитики</h4>
                    <h4 className={cn(css.point, css.line1, css.statistics)}>статистики</h4>
                    <h4 className={cn(css.point, css.line2, css.surveys)}>анкетирования</h4>
                    <h4 className={cn(css.point, css.line2, css.votes)}>голосований</h4>
                </div>
                <div className={css.btn}>
                    <Button href={AccountRoutes.authRegister.link}>Подключиться</Button>
                </div>
            </div>
        </div>
    </section>
);
